.project-header {
	display: flex;
	padding: 12px 30px;
	align-items: center;
	border-radius: 3px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	// Elemental Theme v1.0
	// background: var(--primary-color);
	// Elemental Theme v2.0
	background: var(--secondary-color);
	color: var(--primary-text);
}

.container-header {
	display: flex;
	padding: 12px 30px;
	min-height: 56px;
	align-items: center;
	border-radius: 3px;
	// Elemental Theme v1.0
	// background: var(--primary-color);
	// Elemental Theme v2.0
	background: var(--secondary-color);	
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	color: var(--primary-text);

	.panel-info {
		display: flex;
		padding: 4px 0px;
		gap: 8px;
		cursor: pointer;
	}
}

.project-details-container {
	background: var(--panel-details-bg-color);
	color: var(--panel-details-text-color);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	.project-details-content {
		padding: 16px 0px 0px 0px;
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 100%;
		width: 60%;
		position: relative;
		gap: 20px;
	}

	.task-details-content {
		margin-top: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
	}
}

// Panel Expanded Container
.panel-expanded-container {
}

// Panel Items
.meta-data-container {
	display: grid;
	column-gap: 20px;
	row-gap: 20px;

	.meta-data-item {
		display: grid;
		grid-template-rows: auto auto;
		width: 100px;
	}
}

.panel-item-small-container {
	display: grid;
	column-gap: 20px;
	grid-template-rows: 20px auto;
	padding: 20px;
	background-color: rgba(123, 123, 123, 0.2);
	flex-grow: 1;
}

.panel-detail-label {
	height: min-content;
	text-align: left;
	font-size: 12px;
	white-space: nowrap;
	opacity: 0.45;
}

.panel-item-content {
	font-size: 14px;
	text-align: left;
	height: min-content;
}
