.ant-table-wrapper {
	&.timesheet-management-table {
		background-color: transparent;
		height: min-content;
		padding: 0;

		.ant-table.ant-table-bordered {
			> .ant-table-container {
				border-inline-start: none;
				border-top: none;

				> .ant-table-content {
					> table {
						> thead {
							> tr {
								// Disable the borders in the row
								> th {
									border-inline-end: 0px;

									// Disable the start border on the first child
									&:first-child {
										border-inline-start: 0px;
									}

									// Disable the end border on the last child
									&:last-child {
										border-inline-end: 0px;
									}
								}
							}
						}

						> tbody {
							background: var(--timewriting-table-bg-color);
							> tr {
								> td {
									border-inline-end: var(--audit-table-border);
									&:first-child {
										border-inline-start: var(--audit-table-border);
									}

									&:last-child {
										border-inline-end: var(--audit-table-border);
									}

									padding: 12px 16px;
								}
							}
							> tr.ant-table-placeholder {
								> td {
									border-bottom: none;
								}
							}
						}
					}
				}
			}
		}

		.ant-table-thead {
			> tr {
				> th,
				td {
					background: rgb(12, 12, 42) !important;
					color: var(--primary-text) !important;
					font-weight: 500;
					border-bottom: none;
				}
			}
		}

		.ant-table-column-sorter {
			color: var(--primary-text);
		}

		.ant-table-tbody {
			transition: background-color 0.3s;

			> tr {
				height: 96px;
				background-color: #ffffff40;

				// &:hover {
				// 	background-color: #ffffff40 !important;
				// }

				> td {
					cursor: pointer;
					border-top: var(--audit-table-border);
					border-bottom: var(--audit-table-border);

					// &:hover {
					// 	background-color: #ffffff40 !important;
					// }

					&:first-child {
						background: var(--secondary-color);

						/* Ensure the first child keeps the same background on row hover */
						&:hover {
							background: var(--secondary-color) !important;
						}
					}
				}

				/* Keep the first td's background color the same when hovering the row */
				&:hover > td:first-child {
					background: var(--secondary-color) !important;
				}

				/* Disable Ant Design hover effect on the row */
				.ant-table-cell-row-hover {
					background: transparent !important;
				}
			}
		}

		.ant-spin-nested-loading,
		.ant-spin-container,
		.ant-table-container,
		.ant-table-content {
			height: 100%;
			text-align: left;
		}

		.ant-spin-nested-loading {
			> div {
				.ant-spin {
					max-height: inherit;
				}
			}
		}
	}
}
