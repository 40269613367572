.overview-content {
	flex: 1;
	display: flex;
	flex-wrap: inherit;
	flex-direction: column;
	align-items: flex-start;
	padding: 0;
	gap: 0;
	// overflow-x: auto;
	height: 100%;
	max-height: 100%;
	position: relative;

	.overview-map {
		display: flex;
		flex: 1;
		align-items: flex-start;
		// gap: 25px;
		align-self: stretch;
		// overflow: auto;
		position: relative;

		.sub-container {
			position: absolute;
			z-index: 401;
			height: 100%; // set the height to `100%` instead of `auto` to make sure it not only grow as much as its content, but grows to its parent's height
			padding: 10px 30px 10px 0;
			right: 0;
			display: flex;
			align-items: flex-start;

			.project-list-container {
				background-color: rgba(255, 255, 255, 0.45);
				width: 100%;
				height: auto;
				max-height: 100%;
				padding: 10px;
				display: flex;
				flex-direction: column;
				overflow-y: auto;
			}
		}
	}
}

.overview-card-list-container {
	min-width: 407px;
	border-radius: 5px;
	gap: 10px;
	box-shadow: 0px 4px 4px 0px #00000040;
	transition: height 3s ease;
}

.detailed-card {
	cursor: pointer;
	background: var(--ribbon-bg-color);
	color: var(--ribbon-text-color);
	box-shadow: 0px 4px 4px 0px #00000040;
	border: 2px solid var(--default-btn-color);
	border-radius: 5px;
	padding: 15px 30px 15px 30px;

	.detailed-card-label {
		opacity: 0.45;
		font-size: 14px;
		line-height: 25px;
	}

	.detailed-card-title {
		white-space: normal;
		display: inline;
		overflow-wrap: break-word;
		// Elemental Theme v2.0
		color: var(--default-btn-color);
	}
}

// Advanced Filter
.filter-area {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
	align-self: stretch;
	background: var(--ribbon-bg-color);
	transition: 0.3s ease-in-out;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	&.show-filter {
		padding: 12px 30px !important;
		transform: translateY(0);
	}
	&.hide-filter {
		padding: 0px 30px !important;
		transform: translateY(-150%);
	}
}

// Updated: 30/9/2024
.panel-main-buttons-container {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

// Updated: 1/10/2024
.operations-tag {
	margin: 0;
	padding: 2px 8px;
	display: flex;
	align-items: center;
	color: var(--primary-text);
	background-color: var(--default-btn-color);
	border: 1px solid var(--secondary-text);
}
