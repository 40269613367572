@import "./_functions.scss";

/*
// Elemental Theme V1.0
$primary-color: #0d5257;
$secondary-color: #299ba3;
$default-btn-color: #299ba3;
$danger-btn-color: #ff4d4f;
*/

// Elememtal Theme v2.0
$primary-color: #060629;
$secondary-color: #00003c;
$default-btn-color: #00bebe;
$danger-btn-color: #ff4d4f;

/* Light Theme */
$light-theme: (
	// Loading Screen
	/**/ loading-screen-bg: #e9e9e9,
	//
	// Default Button
	/**/ default-btn-color: $default-btn-color,
	/**/ default-btn-text-color: #00003cd9,
	//
	// Danger Button
	/**/ danger-btn-color: $danger-btn-color,
	/**/ danger-btn-text-color: getTextColor($danger-btn-color),
	//
	// No Access
	/**/ no-access-background-color: #ffffff,
	//
	// Deliverables
	/**/ deliverable-side-container-bg: #ffffff,
	/**/ overview-card-bg: #f8f8f8,
	/**/ overview-card-text: getTextColor(#f8f8f8),
	//
	// Theme Main Color
	/**/ theme-main-bg-color: #f8f8f8,
	/**/ theme-main-text-color: getTextColor(#f8f8f8),
	/**/ theme-main-hover-color: darken(#f8f8f8, 10%),
	/**/ theme-main-hover-text-color: getTextColor(darken(#f8f8f8, 10%)),
	//
	// Primary, Secondary Text Colors - Elemental Theme v2.0
	/**/ primary-text: getTextColor(#060629),
	/**/ secondary-text: white,
	//
	// Progress bar
	/**/ progress-inner-bg: #707070,
	/**/ progress-bar-color:
		linear-gradient(
			90deg,
			darken($secondary-color, 20%) 0%,
			$secondary-color 100%
		),
	/**/ progress-bar-text-color: getTextColor(darken($secondary-color, 20%)),
	//
	// Project Container
	/**/ container-bg-color: #e9e9e9,
	//
	// Header & Footer
	/**/ layout-header-footer-bg: #efefef,
	/**/ layout-header-footer-text: getTextColor(#efefef),
	//
	// Breadcrumb
	/**/ breadcrumb-bg-last: rgba(0, 0, 0, 0.1),
	/**/ breadcrumb-text-color-last: #00bebe,
	//
	// Project details, Well project details, Task details panel
	/**/ panel-details-bg-color: #f8f8f8,
	/**/ panel-details-text-color: getTextColor(#f8f8f8),
	//
	// Ribbon
	/**/ ribbon-bg-color: #f8f8f8,
	/**/ ribbon-text-color: getTextColor(#f8f8f8),
	//
	// Checkbox Dropdown
	/**/ checkbox-dropdown-bg-color: #f0f2f5,
	/**/ checkbox-dropdown-text-color: getTextColor(#f0f2f5),
	//
	// Project Cards
	/**/ project-cards-bg: #f8f8f8,
	/**/ project-cards-text-color: getTextColor(#f8f8f8),
	//
	// Help Center page
	/**/ help-center-bg: #e9e9e9,
	//
	// Side container Body
	/**/ side-container-bg: #ffffff,
	//
	// Container
	/**/ container-text-color: getTextColor(#ffffff),
	/**/ container-footer-bg-color: #f2f2f2,
	/**/ container-inside-bg-color: #f3f4f5,
	//
	// Footer
	/**/ modal-footer-color: #f2f2f2,
	//
	// Generic Table Header
	/**/ generic-table-header-bg-color: #f2f2f2,
	/**/ generic-table-header-text-color: getTextColor(#f2f2f2),
	// Generic Table Body
	/**/ generic-table-body-bg-color-hover: rgb(224, 224, 224),
	// Generic Table Border
	/**/ generic-table-border-color: #0000004d,
	//
	// Comment Box Color
	/**/ comment-box-border-color: #000000,
	/**/ comment-box-date-time: rgba(0, 0, 0, 0.6),
	//
	// Input Color
	/**/ input-text-color: #000000,
	/**/ input-text-color-hover: #00000040,
	/**/ input-bg-color: #f9f9f9,
	/**/ input-bg-color-hover: #00000040,
	/**/ input-border-color: 1px solid #d3d3d3,
	//
	// Disabled Input Color
	/**/ input-bg-disabled: #f5f5f5,
	/**/ input-color-disabled: #00000040,
	/**/ input-border-disabled: #d9d9d9,
	//
	// Search Bar Color
	/**/ search-bg-color: transparent,
	/**/ search-text-light-color: #ffffffb5,
	/**/ search-text-dark-color: #000000,
	//
	// Help Center Search Bar Color
	/**/ help-center-search-bg-color: transparent,
	/**/ help-center-search-text-color: #0000004d,
	divider-border-color: rgba(0, 0, 0, 0.3),
	/**/ notification-icon-bg: #e7e7e7,
	//
	/**/ tenant-button-bg-hover: rgba(0, 0, 0, 0.1),
	//
	// Box Shadow
	/**/ box-shadow-primary: rgba(0, 0, 0, 0.5) 0px 4px 8px 0px,
	/**/ box-shadow-secondary: rgba(0, 0, 0, 0.6) 0px 6px 20px 0px,
	//
	// Audit Log Table Border
	/**/ audit-table-border: 1px solid #00000026,
	//
	// Phase & Task Dragging Activity
	/**/ phase-task-drag-color: #f8f8f8,
	//
	// Timewriting Table
	/**/ timewriting-table-container-bg: white,
	//
	// Table Hover
	/**/ table-hover-bg-color: rgba(0, 0, 0, 0.1)
);

/* Dark Theme */
$dark-theme: (
	// Loading Screen
	/**/ loading-screen-bg: $primary-color,
	//
	// No Access
	// no-access-background-color: linear-gradient(180deg, #000000 3.65%, $primary-color 100%),
	/**/ no-access-background-color:
		linear-gradient(0deg, rgba(0, 0, 34, 0.85) 0%, rgba(56, 56, 96, 0.85) 100%),
	//
	// Deliverables - Elemental Theme v1.0
	// deliverable-side-container-bg: rgba(31, 31, 31, 0.85),
	// Deliverables - Elemental Theme v2.0
	/**/ deliverable-side-container-bg: #212150,
	//
	/**/ modal-footer-color: #141414,
	/**/ layout-header-footer-bg: darken($primary-color, 10%),
	/**/ layout-header-footer-text: getTextColor(darken($primary-color, 10%)),
	//
	// Project details, Well project details, Task details panel - Elemental Theme v1.0
	/**/ panel-details-bg-color: rgb(59, 59, 59),
	/**/ panel-details-text-color: getTextColor(rgb(59, 59, 59)),
	//
	// Primary, Secondary Text Colors - Elemental Theme v2.0
	/**/ primary-text: getTextColor(#060629),
	/**/ secondary-text: black,
	//
	// Progress Bar
	/**/ progress-bar-color:
		linear-gradient(
			90deg,
			darken($secondary-color, 20%) 0%,
			$secondary-color 100%
		),
	//
	// Breadcrumb
	/**/ breadcrumb-bg-last:
		if(
			luminance(darken($primary-color, 10%)) > 0.5,
			rgba(0, 0, 0, 0.1),
			rgba(255, 255, 255, 0.2)
		),
	//
	// Ribbon
	/**/ ribbon-bg-color: rgb(59, 59, 59),
	/**/ ribbon-text-color: getTextColor(rgb(59, 59, 59)),
	//
	// Checkbox
	/**/ checkbox-dropdown-bg-color: rgb(31, 31, 31),
	/**/ checkbox-dropdown-text-color: getTextColor(rgb(31, 31, 31)),
	//
	// Side Container
	/**/ side-container-bg: rgba(31, 31, 31, 0.85),
	//
	// Container
	/**/ container-bg-color: rgb(44, 43, 43),
	/**/ container-text-color: getTextColor(rgba(31, 31, 31, 0.85)),
	/**/ container-footer-bg-color: #141414,
	/**/ container-inside-bg-color: rgb(59, 59, 59),
	//
	// Help Center Page
	/**/ help-center-bg:
		linear-gradient(180deg, #000 0%, lighten($primary-color, 10%) 100%),
	//
	// Project Cards
	/**/ project-cards-bg: darken($primary-color, 5%),
	/**/ project-cards-text-color: getTextColor(darken($primary-color, 5%)),
	//
	// Generic Table Header
	/**/ generic-table-header-bg-color: #141414,
	/**/ generic-table-header-text-color: rgba(255, 255, 255, 0.85),
	//
	// Generic Table Body
	/**/ generic-table-body-bg-color: #ffffff00,
	/**/ generic-table-body-bg-color-hover: rgba(255, 255, 255, 0.05),
	//
	// Generic Table Border
	/**/ generic-table-border-color: rgba(217, 217, 217, 0.3),
	//
	// Disabled Input Color
	/**/ input-bg-disabled: #1f1f1f,
	/**/ input-border-disabled: #434343,
	/**/ input-color-disabled: #434343,
	//
	// Input Color
	/**/ input-text-color: #ffffffd9,
	/**/ input-text-color-hover: #404040,
	/**/ input-bg-color: #141414,
	/**/ input-bg-color-hover: #00000040,
	/**/ input-border-color: 1px solid rgba(217, 217, 217, 0.3),
	//
	// Comment Box Color
	/**/ comment-box-bg-color: rgba(255, 255, 255, 0.1),
	/**/ comment-box-date-time: rgba(248, 248, 248, 0.45),
	//
	// Search Bar Color
	/**/ search-bg-color: transparent,
	/**/ search-text-light-color: #ffffffb5,
	/**/ search-text-dark-color: #ffffffb5,
	//
	// Help Center Search Bar Color
	/**/ help-center-search-bg-color: transparent,
	/**/ help-center-search-text-color: rgba(255, 255, 255, 0.7098039216),
	//
	/**/ notification-icon-bg: #ffffff26,
	//
	/**/ tenant-button-bg-hover: rgba(255, 255, 255, 0.1),
	divider-border-color: rgba(125, 125, 125, 1),
	//
	// Box Shadow
	/**/ box-shadow-primary: rgba(0, 0, 0, 0.5) 0px 4px 8px 0px,
	/**/ box-shadow-secondary: rgba(0, 0, 0, 0.6) 0px 6px 20px 0px,
	//
	// Audit Log Table Border
	/**/ audit-table-border: 1px solid rgba(255, 255, 255, 0.25),
	//
	// Phase & Task Dragging Activity
	/**/ phase-task-drag-color: #242424,
	//
	// Timewriting Table
	/**/ timewriting-table-container-bg: #414166,
	//
	// Table Hover
	/**/ table-hover-bg-color: rgba(255, 255, 255, 0.1)
);

/* Dark Theme Components */
$dark-terra: (
	// Loading Screen
	/**/ loading-screen-bg: $primary-color,
	//
	// Buttons
	default-btn-color: $default-btn-color,
	default-btn-text-color: #00003cd9,
	danger-btn-color: $danger-btn-color,
	danger-btn-text-color: getTextColor($danger-btn-color),
	//
	// Primary, Secondary Colors - Elemental Theme v1.0
	// primary-color: #0d5257,
	// secondary-color: #299ba3,
	//
	// Primary, Secondary Colors - Elemental Theme v2.0
	/**/ primary-color: #060629,
	/**/ secondary-color: #00003c,
	//
	// Primary, Secondary Text Colors - Elemental Theme v2.0
	/**/ primary-text: getTextColor(#060629),
	/**/ secondary-text: black,
	//
	// No Access
	// no-access-background-color: linear-gradient(180deg, #000000 3.65%, #087c85 100%)
	/**/ no-access-background-color:
		linear-gradient(0deg, rgba(0, 0, 34, 0.85) 0%, rgba(56, 56, 96, 0.85) 100%),
	//
	// Deliverables
	// deliverable-side-container-bg: #0c3945,
	/**/ deliverable-side-container-bg: #212150,
	//
	modal-footer-color: #001516,
	//
	// Header - Elemental v1.0
	// layout-header-footer-bg: #041e20,
	// layout-header-footer-text: getTextColor(#041e20),
	//
	// Header & Footer - Elemental v2.0
	/**/ layout-header-footer-bg: #060629,
	/**/ layout-header-footer-text: getTextColor(#041e20),
	//
	// Project details, Well project details, Task details panel - Elemental Theme v1.0
	// panel-details-bg-color: #062d30,
	// panel-details-text-color: getTextColor(#062d30),
	//
	// Project details, Well project details, Task details panel - Elemental Theme v2.0
	/**/ panel-details-bg-color: #66668a4d,
	/**/ panel-details-text-color: getTextColor(#062d30),
	//
	// BreadCrumb - Elemental v1.0
	// breadcrumb-bg-last: rgba(255, 255, 255, 0.2),
	//
	// BreadCrumb - Elemental v2.0
	/**/ breadcrumb-bg-last: rgba(255, 255, 255, 0.2),
	/**/ breadcrumb-text-color-last: #00bebe,
	//
	// Elemental Theme v1.0
	//container-bg-color: #03282b,
	// Elemental Theme v2.0
	container-bg-color: #232345,
	//
	// Ribbon - Elemental Theme v1.0
	// ribbon-bg-color: #062d30,
	// ribbon-text-color: getTextColor(#062d30),
	//
	// Ribbon - Elemental Theme v2.0
	/**/ ribbon-bg-color: #131344,
	/**/ ribbon-text-color: #ffffff,
	//
	checkbox-dropdown-bg-color: rgb(31, 31, 31),
	checkbox-dropdown-text-color: getTextColor(rgb(31, 31, 31)),
	//
	// Side Container - Elemental Theme v1.0
	// side-container-bg: rgba(31, 31, 31, 0.85),
	// Side Container - Elemental Theme v2.0
	/**/ side-container-bg: #66668a4d,
	//
	// Container
	/**/ container-text-color: getTextColor(rgba(31, 31, 31, 0.85)),
	/**/ container-footer-bg-color: #0c0c2a,
	//
	// Help Center
	/**/ help-center-bg: linear-gradient(180deg, #000 0%, #087c85 100%),
	//
	// Project Cards - Elemental Theme v1.0
	// project-cards-bg: #003235,
	// project-cards-text-color: #ffffff,
	//
	// Project Cards - Elemental Theme v2.0
	/**/ project-cards-bg: #131344,
	/**/ project-cards-text-color: #ffffff,
	//
	// Container Inside Background Color - Elemental Theme v1.0
	// container-inside-bg-color: linear-gradient(180deg, rgba(0, 27, 29, 0.85) 0%, rgba(51, 90, 92, 0.85) 100%),
	//
	// Container Inside Background Color - Elemental Theme v2.0
	/**/ container-inside-bg-color:
		linear-gradient(0deg, rgba(0, 0, 34, 0.85) 0%, rgba(56, 56, 96, 0.85) 100%),
	//
	// Generic Table Header - Elemental Theme v1.0
	// generic-table-header-bg-color: rgba(20, 20, 20, 1),
	// Generic Table Header - Elemental Theme v2.0
	/**/ generic-table-header-bg-color: #0c0c2a,
	/**/ generic-table-header-text-color: white,
	//
	// Generic Table Body - Elemental Theme v1.0
	// generic-table-body-bg-color: rgba(123, 123, 123, 0.3),
	// Generic Table Body - Elemental Theme v2.0
	/**/ generic-table-body-bg-color: #2d2d51,
	/**/ generic-table-body-bg-color-hover: #0000004d,
	/**/ generic-table-body-text-color: white,
	//
	// Generic Table Border - Elemental Theme v1.0
	// generic-table-border-color: rgba(255, 255, 255, 0.4),
	//
	// Generic Table Border - Elemental Theme v2.0
	/**/ generic-table-border-color: rgba(217, 217, 217, 0.3),
	//
	// Disabled Input Color
	/**/ input-bg-disabled: #1f1f1f,
	/**/ input-border-disabled: #434343,
	/**/ input-color-disabled: #434343,
	//
	// Input Color
	/**/ input-text-color: #ffffffd9,
	/**/ input-text-color-hover: #404040,
	/**/ input-bg-color: #141414,
	/**/ input-bg-color-hover: #f5f5f5,
	/**/ input-border-color: 1px solid rgba(217, 217, 217, 0.3),
	//
	// Comment Box Color
	/**/ comment-box-bg-color: rgba(255, 255, 255, 0.1),
	/**/ comment-box-date-time: rgba(248, 248, 248, 0.45),
	//
	// Search Bar Color
	/**/ search-bg-color: ($secondary-color),
	/**/ search-text-light-color: #ffffffb5,
	/**/ search-text-dark-color: #ffffffb5,
	//
	// Help Center Search Bar Color
	/**/ help-center-search-bg-color: transparent,
	/**/ help-center-search-text-color: rgba(255, 255, 255, 0.7098039216),
	//
	/**/ notification-icon-bg: #ffffff26,
	//
	/**/ tenant-button-bg-hover: rgba(255, 255, 255, 0.1),
	divider-border-color: rgba(125, 125, 125, 1),
	//
	// Box Shadow
	/**/ box-shadow-primary: rgba(0, 0, 0, 0.5) 0px 4px 8px 0px,
	/**/ box-shadow-secondary: rgba(0, 0, 0, 0.6) 0px 6px 20px 0px,
	//
	// Audit Log Table Border
	/**/ audit-table-border: 1px solid rgba(255, 255, 255, 0.25),
	//
	// Phase & Task Dragging Activity
	/**/ phase-task-drag-color: #242424,
	//
	// Timewriting Table
	/**/ timewriting-table-container-bg: #414166,
	//
	// Table Hover
	/**/ table-hover-bg-color: rgba(255, 255, 255, 0.1)
);

@mixin general-properties {
	$primary-text: getTextColor($primary-color);
	$secondary-text: getTextColor($secondary-color);
	$default-btn-text: getTextColor($default-btn-color);
	$danger-btn-text: getTextColor($danger-btn-color);

	// Primary, secondary colors
	--primary-color: #{$primary-color};
	--secondary-color: #{$secondary-color};
	--primary-text: #{$primary-text};
	--secondary-text: #{$secondary-text};

	// Buttons
	--default-btn-color: #{$default-btn-color};
	--default-btn-text-color: #{$default-btn-text};
	--danger-btn-color: #{$danger-btn-color};
	--danger-btn-text-color: #{$danger-btn-text};
}

@mixin general-dark-properties {
	$theme-main-text: getTextColor(#242424);
	$hover-bg: lighten(#242424, 10%);
	$hover-text: getTextColor(lighten(#242424, 10%));

	--theme-main-bg-color: #242424;
	--theme-main-text-color: #{$theme-main-text};
	--theme-main-hover-color: #{$hover-bg};
	--theme-main-hover-text-color: #{$hover-text};
	--progress-inner-bg: #434343;
}

:root {
	@each $key, $value in $light-theme {
		--#{$key}: #{$value};
	}
	@include general-properties;
}

[data-theme="dark"] {
	@each $key, $value in $dark-theme {
		--#{$key}: #{$value};
	}
	@include general-properties;
	@include general-dark-properties;
}

[data-theme="dark-terra"] {
	@each $key, $value in $dark-terra {
		--#{$key}: #{$value};
	}
	@include general-dark-properties;
}
