.client-list {
	box-shadow: 0px 4px 4px 0px #00000040;
	background: var(--ribbon-bg-color); //#062d30;
	border: 0px, 0px, 0.5px, 0px;
	gap: 5px;
	width: 100%;
	transition: height 0.3s ease-in-out;

	&.collapsed {
		height: 52px;
	}

	&.expanded {
		height: 150px;
	}
}

.client-list-button {
	width: 109px;
	height: 109px;
	position: relative;

	.client-list-project-name {
		white-space: unset;
	}

	.client-list-project-name-no-data {
		font-style: italic;
	}
}

.client-list-button.ant-btn-default {
	border-color: unset;
	border: 0px;
	background-color: unset;
}

.client-list-button .ant-btn-icon {
	margin-inline-end: 0px !important;
	position: absolute; /* Absolutely position the icon */
	top: 51%; /* Position the top of the icon at the vertical center */
	left: 49.5%; /* Position the left of the icon at the horizontal center */
	transform: translate(
		-50%,
		-50%
	); /* Offset by half the width/height to truly center it */
}

.client-list-button.ant-btn-link {
	color: var(--panel-details-text-color);
}

.client-list-button.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
	color: var(--secondary-text);
	background-color: var(--default-btn-color);
}

.client-list-button-selected {
	color: var(--secondary-text) !important;
	background-color: var(--default-btn-color);
}

.client-list-project-counter {
	color: var(--ribbon-text-color); //white;
	opacity: 0.65;
}

.client-list-button.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover
	.client-list-project-counter,
.client-list-project-counter-selected {
	color: var(--secondary-text);
	opacity: 1;
}

.leaflet-container {
	width: 100%;
	height: 100%;
	background: none;
	outline-offset: 0px;
}

[data-theme="dark"],
[data-theme="dark-terra"] {
	.leaflet-layer,
	.leaflet-control-zoom-in,
	.leaflet-control-zoom-out,
	.leaflet-control-attribution {
		filter: invert(90%) grayscale(100%);
	}
}

.project-detailed-container {
	cursor: pointer;
	background: var(--ribbon-bg-color);
	box-shadow: 0px 4px 4px 0px #00000040;
	border: 2px solid var(--default-btn-color);
	border-radius: 5px;

	padding: 15px 30px 15px 30px;

	.project-title-clickable {
		color: var(--default-btn-color);
		font-size: 20px;
	}
}

.project-mini-container {
	color: var(--border-color);
	background: var(--ribbon-bg-color);
	cursor: pointer;
	padding: 10px 30px 10px 30px;
	border: 2px solid var(--primary-color);
}

.project-mini-container .project-title-clickable {
	color: var(--theme-main-text-color);
	font-weight: normal;
}

.project-mini-container .project-status {
	color: var(--theme-main-text-color);
}

.project-list {
	/* padding-right: 10px; */
	overflow: hidden;
	overflow-y: auto;
}

.project-grid-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* Two columns */
	grid-auto-rows: auto; /* Automatically size rows based on content */
	gap: 10px; /* Adjust the gap between grid items as needed */
}

.project-grid-item {
	border: 1px solid #ccc;
	padding: 10px;
}

.project-list::-webkit-scrollbar-thumb {
	background-color: #696969; /* Color of the thumb */
	border-radius: 10px; /* Rounded corners for the thumb */
}

.project-list::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: transparent;
}

.project-list::-webkit-scrollbar {
	width: 5px;
	display: block;
}

.project-list-status-counter-container {
	min-width: 419px;
	min-height: 45px;
	padding: 10px;
	border-radius: 3px;
	gap: 10px;
	background: var(--primary-color);
	box-shadow: 0px 4px 4px 0px #00000040;
	color: var(--primary-text);
	font-size: 12px;
	margin-bottom: 10px;
}

.project-status {
	display: flex;
	align-items: center;
	font-size: 12px;
	gap: 12px;
}

.project-status-item {
	display: flex;
	justify-content: center;
	width: auto;
	min-width: 87.5px;
	border-radius: 50px;
	padding: 0 10px;
}

.status-counter.ant-avatar.ant-avatar-square {
	padding: 2px;
	border-radius: 4px;
	width: 25px;
	height: 25px;
	line-height: normal;
	margin-right: 8px;
}

.status-counter-in-progress {
	background: #0f709e;
	border: 1px solid #074f71;
	color: #cee7f3;
	// Elemental Theme 2.0
	font-size: 15px;
}

.status-counter-new {
	border: 1px solid #c4c4c4;
	background: #fff;
	color: #838383;
}

.status-counter-completed {
	background: #52c41a;
	border: 1px solid #52c41a;
	color: #ebffe1;
}

.status-counter-active {
	color: rgba(41, 155, 163, 1);
}

.customClusterIcon {
	background-image: url("../image/Pins - map (new).png");
	background-size: cover;
	background-position: center;
	filter: drop-shadow(1px 1px 4px #1e1e1e);
}

.leaflet-marker-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	filter: drop-shadow(1px 1px 4px #1e1e1e);
}

.customClusterIcon div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10px;
	height: 15px;
	margin-top: -5px;
	text-align: center;
}

.customClusterIcon .customClusterIconBackground {
	background: white;
	color: black;
	/* clip-path: circle(40%); */
}

.filter-container {
	width: auto;
	background-color: var(--ribbon-bg-color);
	color: var(--ribbon-text-color);
	box-shadow: 0px 4px 4px 0px #00000040;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	position: absolute;
	right: 30px;
	z-index: 500;

	&.collapsed {
		padding: 5px;
		top: 10px;
		border-radius: 3px;
	}

	&.expanded {
		padding: 5px 15px;
		top: 150px;
		border-radius: 0px 0px 3px 3px;
	}

	.search-input {
		color: var(--checkbox-dropdown-text-color);
	}
}

.current-viewed-company {
	align-items: center;
	padding: 5px 30px 5px 30px;
	border-radius: 3px;
	background-color: var(--ribbon-bg-color);
	box-shadow: 0px 4px 4px 0px #00000040;

	&.collapsed {
		display: block;
	}

	&.expanded {
		display: none;
	}

	.search-input {
		color: var(--checkbox-dropdown-text-color);
	}
}
